import React from 'react';

function Projects() {
  return (
    <div>
      <h1>Projects</h1>
      <a href="./projects/studentManagement">Student Management</a><br></br>
      <a href="./projects">Naithani Traders (WIP)</a>
    </div>
  );
}

export default Projects;
